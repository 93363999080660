#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  color: #fff;
  background-color: #2c51a4;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  transition: all 0.35s;
}
.navbar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  height: 55px;
  padding: 0 calc(24px / 2) 0 0;
}

.navbar-brand-box {
  padding: 0 1.5rem;
  text-align: center;
  width: 250px;
  color: #302f2f;
  /* background: linear-gradient(
      to right,
      rgb(29 124 98) 0%,
      rgb(39 179 141) 100%
    ); */
}
.form-group {
  margin-bottom: 5px;
}
.logo {
  line-height: 80px;
}
.logo .logo-sm {
  display: none;
}
.logo-light {
  display: none;
}
.name {
  font-size: 13px;
  color: #fff;
  margin: 22px 10px;
  font-weight: bold;
  /* font-weight: 300; */
}
.vertical-collpsed .name {
  display: none;
}
.app-search {
  padding: calc(32px / 2) 0;
}
.app-search .form-control {
  border: none;
  outline: none;
  height: 38px;
  padding-left: 40px;
  padding-right: 38%;
  background-color: #d9e4ff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 20px !important;
}
.sidebar-search {
  padding: 11px 3px 0 3px !important;
}
.app-search .search {
  position: absolute;
  z-index: 10;
  font-size: 16px;
  line-height: 38px;
  left: 13px;
  top: 0;
  color: #74788d;
}
.app-search .clear {
  position: absolute;
  z-index: 10;
  font-size: 16px;
  line-height: 38px;
  left: 256px;
  top: 0;
  color: #74788d;
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
}
.media-body-dash {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}
.header-item {
  height: 70px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #fff;
  border: 0;
  border-radius: 0;
}
.header-item:hover {
  color: #e1e1e1;
}
.header-profile-user {
  height: 30px;
  width: 30px;
  background-color: #f6f6f6;
  padding: 3px;
}
.noti-icon i {
  font-size: 22px;
  color: #555b6d;
}
.noti-icon .badge {
  position: absolute;
  top: 12px;
  right: 4px;
}
.notification-item .media {
  padding: 0.75rem 1rem;
}
.notification-item .media:hover {
  background-color: #f6f6f6;
}
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: #74788d;
}
.dropdown-icon-item img {
  height: 24px;
}
.dropdown-icon-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-icon-item:hover {
  border-color: #eff2f7;
}
button:hover,
.userName:hover {
  color: white;
}
.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.avatar-xs {
  height: 4rem;
  width: 4rem;
}

.dropdown-menu {
  height: auto;
  width: 250px;
  border: none;

  box-shadow: 3px 2px 13px 9px rgba(100, 100, 100, 0.1);
}
@media (max-width: 600px) {
  .navbar-header .dropdown {
    position: static;
  }
  .navbar-header .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
  }
}
@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}
.font-size-10 {
  font-size: 10px !important;
}
.font-size-11 {
  font-size: 11px !important;
}
.font-size-12 {
  font-size: 12px !important;
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-15 {
  font-size: 15px !important;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-17 {
  font-size: 17px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-22 {
  font-size: 22px !important;
}
.font-size-24 {
  font-size: 24px !important;
}
.fw-medium {
  font-weight: 500;
}
.font-weight-semibold {
  font-weight: 600;
}
/* .dropdown-menu-end {
    left: auto !important;
    right: 0 !important;
  } */
@media (min-width: 600px) {
  .dropdown-menu-lg {
    width: 320px;
  }
}
a {
  color: #3057ad;
}
.small,
small {
  font-size: 80%;
}
a {
  text-decoration: none !important;
}
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}
.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}
.simplebar-offset {
  direction: inherit !important;
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}
.simplebar-content-wrapper {
  direction: inherit;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  visibility: visible;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  padding: 0 !important;
}
.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.simplebar-content:after,
.simplebar-content:before {
  content: " ";
  display: table;
}
.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}
.simplebar-height-auto-observer-wrapper {
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  -webkit-box-flex: inherit;
  -ms-flex-positive: inherit;
  flex-grow: inherit;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}
.simplebar-height-auto-observer {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}
.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 4px;
  min-height: 10px;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  -webkit-transition: opacity 0s linear;
  transition: opacity 0s linear;
}
.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}
.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}
