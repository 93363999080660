@font-face {
  font-family: "Poppins";
  src: url("./assets/Fonts/Poppins-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.user-card {
  height: 85vh !important;
  background-color: white !important;
}

.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}
