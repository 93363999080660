.vertical-menu {
  width: 250px;
  z-index: 1001;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 54px;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  /* box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03); */
  /* background: linear-gradient(
      to right,
      rgb(29 124 98) 0%,
      rgb(39 179 141) 100%
    ); */
  background: #fff;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px; */
  transition: all 0.35s;
}
.app-search {
  padding: calc(32px / 2) 0;
}
.sidebar-search {
  padding: 11px 3px 0 3px !important;
}
.search-sidebar {
  background: #f2f2f5 !important;
}
.app-search .form-control {
  border: none;
  height: 33px;
  padding-left: 40px;
  padding-right: 38%;
  background-color: #f2f2f5;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
}

.app-search span {
  position: absolute;
  z-index: 10;
  font-size: 16px;
  line-height: 38px;
  left: 13px;
  top: 0;
  color: #74788d;
}

#sidebar-menu {
  padding: 20px 2px;
}

#sidebar-menu ul li a {
  display: block;
  padding: 0.7rem 0.7rem;
  color: #302f2f;
  position: relative;
  font-size: 13px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  text-decoration: none;
}

#sidebar-menu ul li a i {
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 17px;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #726c6c;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

#sidebar-menu ul li a {
  font-size: 1rem;
  font-weight: 500;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}
/* #sidebar-menu ul li a:hover {
    color: #383c40;
  }
  #sidebar-menu ul li a:hover i {
    color: #383c40;
  } */
#sidebar-menu ul li .badge {
  margin-top: 4px;
}
#sidebar-menu ul li ul.sub-menu {
  padding: 0;
  margin-top: 5px;
}

#sidebar-menu ul li ul.sub-menu li a {
  padding: 0.5rem 1rem 0.3rem 3rem;
  font-size: 13px;
  color: #545a6d;
}
.mm-show a {
  font-size: 1rem !important;
}
#sidebar-menu ul li ul.sub-menu li a:hover {
  color: #383c40;
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu {
  padding: 0;
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 4.5rem;
  font-size: 13px;
}
.metismenu {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.metismenu .mm-active .sub-menu {
  display: block;
}
.metismenu li {
  display: block;
  width: 100%;
  padding: 3px 0 0 0;
}
.metismenu li a:hover {
  background: #2f54a9;
  color: #e3e1e1 !important;
  border-radius: 8px;
}
.metismenu li a:hover i {
  color: #e3e1e1 !important;
}

.metismenu .mm-collapse {
  display: none;
}
.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}
.metismenu .mm-collapse.mm-show {
  display: block;
  background: #fff;
  color: #383c40;
}
.metismenu .mm-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
}
.vertical-collpsed .main-content {
  margin-left: 70px;
}
.vertical-collpsed .navbar-brand-box {
  width: 70px !important;
}
.vertical-collpsed .logo span.logo-lg {
  display: none;
}
.vertical-collpsed .logo span.logo-sm {
  display: block;
}
.vertical-collpsed .vertical-menu {
  position: absolute;
  width: 70px !important;
  z-index: 5;
}

.vertical-collpsed .vertical-menu .simplebar-content-wrapper,
.vertical-collpsed .vertical-menu .simplebar-mask {
  overflow: visible !important;
}
.vertical-collpsed .vertical-menu .simplebar-scrollbar {
  display: none !important;
}
.vertical-collpsed .vertical-menu .simplebar-offset {
  bottom: 0 !important;
}
.vertical-collpsed .vertical-menu #sidebar-menu .badge,
.vertical-collpsed .vertical-menu #sidebar-menu .collapse.in,
.vertical-collpsed .vertical-menu #sidebar-menu .menu-title {
  display: none !important;
}
.vertical-collpsed .vertical-menu #sidebar-menu .nav.collapse {
  height: inherit !important;
}
.has-arrow {
  border-radius: 10px;
}
.vertical-collpsed .vertical-menu #sidebar-menu .has-arrow:after {
  display: none;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li {
  position: relative;
  white-space: nowrap;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a {
  padding: 15px 20px;
  min-height: 55px;
  -webkit-transition: none;
  transition: none;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:active,
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:focus,
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:hover {
  color: #383c40;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a i {
  font-size: 1.45rem;
  margin-left: 4px;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a span {
  display: none;
  padding-left: 25px;
}
/* for hover and show sub menu */
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  position: relative;
  width: calc(190px + 70px);
  color: #fff;
  background: #dce4f6;
  -webkit-transition: none;
  transition: none;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a span {
  display: inline;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul {
  display: block;
  left: 70px;
  top: 65px;
  position: absolute;
  width: 190px;
  height: auto !important;
  -webkit-box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
  box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul ul {
  -webkit-box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
  box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 8px 20px;
  position: relative;
  width: 190px;
  z-index: 6;
  color: #2144b8;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a:hover {
  color: #383c40;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul ul {
  padding: 5px 0;
  z-index: 9999;
  display: none;
  background-color: #fff;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li:hover > ul {
  display: block;
  left: 190px;
  height: auto !important;
  margin-top: -36px;
  position: absolute;
  width: 190px;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li > a span.pull-right {
  position: absolute;
  right: 20px;
  top: 12px;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li.active a {
  color: #f8f9fa;
}
@media (max-width: 992px) {
  .vertical-menu {
    display: none;
  }
  .main-content {
    margin-left: 0 !important;
  }
  .sidebar-enable .vertical-menu {
    display: block;
  }
  .banner > div {
    display: flex; /* Set flex-column container */
    flex-direction: column;
    text-align: center;
    justify-content: center; /* Align flex items horizontally */
    align-items: center;
  }
}
.sidebar-enable .vertical-menu {
  display: block;
}
.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.mm-active {
  margin: 8px 0;
}
.mm-active > a {
  color: #fff !important;
  background-color: #2c51a4;
}
.mm-active > a i {
  color: #f1f1f1 !important;
}
/* .mm-active > a i:hover {
  color: #fff !important;
} */

.mm-active .active {
  color: #2c51a4 !important;
  background-color: #fff;
}
.mm-active .active i {
  color: #1ca07b !important;
}
.menu-title {
  color: #6a7187;
}
.font-size-10 {
  font-size: 10px !important;
}
.font-size-11 {
  font-size: 11px !important;
}
.font-size-12 {
  font-size: 12px !important;
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-15 {
  font-size: 15px !important;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-17 {
  font-size: 17px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-22 {
  font-size: 22px !important;
}
.font-size-24 {
  font-size: 24px !important;
}
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}
.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}
.simplebar-offset {
  direction: inherit !important;
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}
.simplebar-content-wrapper {
  direction: inherit;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  visibility: visible;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  padding: 0 !important;
}
.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.simplebar-content:after,
.simplebar-content:before {
  content: " ";
  display: table;
}
.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}
.simplebar-height-auto-observer-wrapper {
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  -webkit-box-flex: inherit;
  -ms-flex-positive: inherit;
  flex-grow: inherit;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}
.simplebar-height-auto-observer {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}
.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 4px;
  min-height: 10px;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  -webkit-transition: opacity 0s linear;
  transition: opacity 0s linear;
}
.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}
.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

ul li.active:parent {
  display: block !important;
}
