.footer {
  bottom: 0;
  /* padding: 5px calc(10px / 2); */
  padding-top: 3px;
  position: absolute;
  right: 0;
  background: #2c51a4;
  color: #fff;
  position: fixed;
  left: 250px;
  transition: all 0.35s;
}
@media (max-width: 991.98px) {
  .footer {
    left: 0;
  }
}
.vertical-collpsed .footer {
  left: 70px;
}
@media (max-width: 991.98px) {
  .vertical-collpsed .footer {
    left: 0;
  }
}
